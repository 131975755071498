let cheerio = require('cheerio');

module.exports = function specialFormatter(html, removeOuterParagraph = false) {
  const dom = cheerio.load(html);
  const imageBlockquotes = dom('figure[data-type="image"] + blockquote');


  if (imageBlockquotes.length < 1) {

    if (removeOuterParagraph && dom('body > p').length === 1) {
      return dom('body > p').html()
    }

    return html;
  }

  imageBlockquotes.each((idx, node) => {
    const blockQuote = dom(node);
    const image = blockQuote.prev();
    const figCaption = dom("<figcaption />").html(blockQuote.html());

    image.append(blockQuote);
    blockQuote.replaceWith(() => {
      return figCaption;
    });
  });

  if (removeOuterParagraph && dom('body > p').length === 1) {
    return dom('body > p').html()
  }

  return dom('body').html();
};
