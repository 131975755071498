import React from "react";
import { navigateTo } from "gatsby-link";
import Layout from '../components/layout'
import get from 'lodash/get'
import specialFormatter from '../utility/special-formatter'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);

    const contactSubjectLines = get(this, 'props.data.contentfulSite.contactSubjectLines');

    this.state = {formSubmitted: false};

    if (!!contactSubjectLines && contactSubjectLines.length > 0) {
      this.state.subject = contactSubjectLines[0];
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .catch(error => alert(error));
    this.setState({ formSubmitted: true });
  };

  renderSubjectLine() {
    const contactSubjectLines = get(this, 'props.data.contentfulSite.contactSubjectLines');

    if (!!contactSubjectLines && contactSubjectLines.length > 0) {
      const options = contactSubjectLines.map(item => {
        return (<option value={item}>{item}</option>)
      })

      return (
        <div className="wy-control-group fluid-input">
          <label>
            Subject:
          </label>
          <div className="wy-control">
            <select name="subject" onChange={this.handleChange}>
              { options }
            </select>
          </div>
        </div>
      );
    }

    return (
      <div className="wy-control-group fluid-input">
        <label>
          Subject:
        </label>
        <div className="wy-control">
          <input type="text" name="subject" onChange={this.handleChange} />
        </div>
      </div>
    );
  }

  render() {
    const contactTextHTML = get(this, 'props.data.contentfulSite.contactFormText.childContentfulRichText.html');

    return (
      <Layout>
        <div class="page-title">
          <div class="container">
            <h1>Contact Us</h1>
          </div>
        </div>

        <div class="container contact">
          <div class="half">
            <div
              dangerouslySetInnerHTML={{
                __html: (contactTextHTML !== null) ? specialFormatter(contactTextHTML) : null,
              }}
            />
          </div>
          <div class="half">
            {this.state.formSubmitted ?
              <div class="wy-alert wy-alert-success">
                <div class="wy-alert-title">Success</div>
                <p>E-mail sent! Thank you for contacting us.</p>
              </div>
            :
              <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                  </label>
                  <input name="bot-field" onChange={this.handleChange} />
                </div>
                <div className="wy-control-group fluid-input">
                  <label>
                    Name
                  </label>
                  <div className="wy-control">
                    <input type="text" name="name" onChange={this.handleChange} required />
                  </div>
                </div>
                <div className="wy-control-group fluid-input">
                  <label>
                    Your email:
                  </label>
                  <div className="wy-control">
                    <input type="email" name="email" onChange={this.handleChange} required />
                  </div>
                </div>
                { this.renderSubjectLine() }
                <div className="wy-control-group fluid-input">
                  <label>
                    Message:
                  </label>
                  <div className="wy-control">
                    <textarea name="message" onChange={this.handleChange} style={{ minHeight: 200 }} required />
                  </div>
                </div>
                <button type="submit" className="btn btn-neutral">Send</button>
              </form>
            }

          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ContactQuery {
    contentfulSite {
      contactFormText {
        childContentfulRichText {
          html
        }
      }
      contactSubjectLines
    }
  }
`
